import SubmitButton from "../../components/SubmitButton/SubmitButton";
import React, {FormEvent, useState} from "react";
import {Auth} from "aws-amplify";
import {useFormFields} from "../../libs/HooksLib";
import {handleLogout} from "../../services/HitpLogin";
import {AppPropsType} from "../../App";

export default function ChangeEmail(props: AppPropsType) {
  const [fields, handleFieldChange] = useFormFields({
    newEmail: "",
    confirmNewEmail: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("")
  const [emailChanged, setEmailChanged] = useState(false);

  async function handleFormSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();
    setIsLoading(true);
    if (fields.newEmail === fields.confirmNewEmail) {
      try {
        const user = await Auth.currentAuthenticatedUser()
        await Auth.updateUserAttributes(user, {email: fields.newEmail});
        setEmailChanged(true);
      } catch (err) {
        setError((err as Error).message);
      }
    } else {
      setError("New email addresses do not match.");
    }
    setIsLoading(false);
  }

  function handleFieldUpdate(event: FormEvent<HTMLInputElement>) {
    setError('');
    handleFieldChange(event);
  }

  function validateUpdateForm(): boolean {
    return (
      fields.newEmail.length > 0 &&
      fields.confirmNewEmail.length > 0
    );
  }

  async function handleSuccessClick() {
    await handleLogout();
    props.history.push("/login");
  }

  function handleReturnToHomePress() {
    props.history.push("/");
  }

  function renderForm() {
    return (
      <div className={"col-sm-10 col-md-8 col-lg-6 col-xl-4 offset-sm-1 offset-md-2 offset-lg-3 offset-xl-4 centered-section"}>
        <h2>Change email</h2>
        <div className={"subheading"}>Enter new email address and confirm.</div>
        <form onSubmit={handleFormSubmit} autoComplete={"off"}>
          <div>New email</div>
          <input
            id="newEmail"
            className="text-field"
            type="text"
            value={fields.newEmail}
            onChange={handleFieldUpdate}
          />
          <div>Confirm new email</div>
          <input
            id="confirmNewEmail"
            className="text-field"
            type="text"
            value={fields.confirmNewEmail}
            onChange={handleFieldUpdate}
          />
          {error !== '' &&
            <span id="error-text" className="form-error">{error}</span>
          }
          <SubmitButton
            id={"change-email-btn"}
            type="submit"
            isLoading={isLoading}
            disabled={!validateUpdateForm()}
            className={"primary-button"}
          >
            Change email
          </SubmitButton>
          <button
            id="return-to-home-btn"
            type={"button"}
            className={"secondary-button"}
            onClick={handleReturnToHomePress}
          >
            Return to home
          </button>
        </form>
      </div>
    )
  }

  function renderSuccessMessage() {
    return (
      <div className={"col-sm-10 col-md-8 col-lg-6 col-xl-4 offset-sm-1 offset-md-2 offset-lg-3 offset-xl-4 centered-section"}>
        <h2>Email changed</h2>
        <div className={"sub-heading"}>Your email address has been changed to {fields.newEmail}</div>
        <div className={"sub-heading"}>You must now log in again with your new email address.</div>
        <button
          id="return-to-home-btn"
          type={"button"}
          className={"return-button"}
          onClick={handleSuccessClick}
        >
          Return to login
        </button>
      </div>
    )
  }

  return (
    <div className={"row fill"} id={"change-email"}>
      {!emailChanged ? renderForm() : renderSuccessMessage()}
    </div>
  )
}
