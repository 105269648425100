import React from "react";
import {getCurrentUser, handleLogout} from "../../services/HitpLogin";
import PageFooter from "./Footer";
import logoDark from "../../images/mdu-ggc-logo.svg";
import Modal from "react-modal";
import "./Sidebar.scss";

export function DesktopSidebar() {
  async function handleLogoutButton() {
    await handleLogout();
  }

  return (
    <div className={"hitp-sidebar container"} id={"sidebar"}>
      <div className={"sidebar-header"}>
        {getCurrentUser()?.idToken.payload.email}
      </div>
      <hr/>
      {/*<div className={"sidebar-link"}>*/}
      {/*  <a href={"/change-email"}>Change email address</a>*/}
      {/*</div>*/}
      <div className={"sidebar-link"}>
        <a href={"/change-password"}>Change password</a>
      </div>
      <div className={"sidebar-link"}>
        <a href={"/login"} onClick={handleLogoutButton}>Log out</a>
      </div>
    </div>
  )
}

interface mobileSidebarProps {
  visible: boolean,
  closeSidebar: () => void
}

export function MobileSidebar(props: mobileSidebarProps) {

  const userLoggedIn = getCurrentUser();

  return (
    <Modal
      isOpen={props.visible}
      onRequestClose={() => props.closeSidebar()}
      contentLabel={"Sidebar"}
      className={"mobile-sidebar"}
      overlayClassName={"sidebar-overlay"}
      ariaHideApp={false}
      id={"mobile-sidebar"}
      closeTimeoutMS={500}
    >
      <div className={"container-fluid fill d-flex flex-column justify-content-between"}>
        <div className={"container-fluid"}>
          {userLoggedIn && (
            <div id={"sidebar-content"}>
              <div className={"sidebar-header"}>
                {getCurrentUser()?.idToken.payload.email}
              </div>
              {/*<div className={"sidebar-link"}>*/}
              {/*  <a href={"/change-email"}>Change email address</a>*/}
              {/*</div>*/}
              <div className={"sidebar-link"}>
                <a href={"/change-password"}>Change password</a>
              </div>
              <div className={"sidebar-link"}>
                <a href={"/login"} onClick={handleLogout}>Log out</a>
              </div>
            </div>
          )}
        </div>
        <div className={"container-fluid"}>
          <PageFooter/>
          <img src={logoDark} className={"mobile-sidebar-logo"} id={"mdu-ggc-logo"} alt={"mdu-ggc-logo"}/>
        </div>
      </div>
    </Modal>
  )
}
