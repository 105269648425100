import SubmitButton from "../../components/SubmitButton/SubmitButton";
import React, {FormEvent, useState} from "react";
import {Auth} from "aws-amplify";
import {useFormFields} from "../../libs/HooksLib";
import {AppPropsType} from "../../App";
import {AuthenticationState, getAuthState, handleInteractiveLogin, handleLogout} from "../../services/HitpLogin";

interface PasswordResetProps extends AppPropsType {
  email: string
}

export default function PasswordReset(props: PasswordResetProps) {
  const [fields, handleFieldChange] = useFormFields({
    code: "",
    password: "",
    confirmPassword: ""
  });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("")

  async function handleSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();
    setIsLoading(true);
    if (fields.password === fields.confirmPassword) {
      try {
        await Auth.forgotPasswordSubmit(
          props.email,
          fields.code,
          fields.password
        );
        setIsLoading(false);
        await handleInteractiveLogin(props.email, fields.password)
        props.history.push("/");
      } catch (err) {
        setError((err as Error).message);
        fields.password = '';
        fields.confirmPassword = '';
        setIsLoading(false);
      }
    } else {
      setError("Passwords do not match.");
      setIsLoading(false);
    }
  }

  function handleFieldUpdate(event: FormEvent<HTMLInputElement>) {
    setError('');
    handleFieldChange(event);
  }

  function validateForm(): boolean {
    return (
      fields.code.length > 0 &&
      fields.password.length > 0);
  }

  async function handleReturnToSignInPress() {
    if (getAuthState() !== AuthenticationState.None) {
      await handleLogout();
    }
    props.history.push("/login");
  }

  return (
    <div className={"row fill"} id={"reset-password"}>
      <div className={"col-sm-10 col-md-8 col-lg-6 col-xl-4 offset-sm-1 offset-md-2 offset-lg-3 offset-xl-4 centered-section"}>
        <h2>Change password</h2>
        <div className={"subheading"}>Enter the code sent to your email address as well as a new password.</div>
        <form onSubmit={handleSubmit} autoComplete={"off"}>
          <div>Reset code</div>
          <input
            id="code"
            className="text-field"
            type="text"
            onChange={handleFieldUpdate}
            value={fields.code}
          />
          <div>New password</div>
          <input
            id="password"
            className="text-field"
            type="password"
            value={fields.password}
            onChange={handleFieldUpdate}
          />
          <div>Confirm password</div>
          <input
            id="confirmPassword"
            className="text-field"
            type="password"
            value={fields.confirmPassword}
            onChange={handleFieldUpdate}
          />
          {error !== '' &&
            <span id="error-text" className="form-error">{error}</span>
          }
          <SubmitButton
            id={"change-password-btn"}
            type="submit"
            isLoading={isLoading}
            disabled={!validateForm()}
            className={"primary-button"}
          >
            Change password
          </SubmitButton>
          <button
            id="return-to-sign-in-btn"
            type={"button"}
            className={"secondary-button"}
            onClick={handleReturnToSignInPress}
          >
            Return to sign in
          </button>
        </form>
      </div>
    </div>
  );
}
