import SubmitButton from "../../components/SubmitButton/SubmitButton";
import React, {FormEvent, useState} from "react";
import {Auth} from "aws-amplify";
import {useFormFields} from "../../libs/HooksLib";
import {AppPropsType} from "../../App";
import {getChallengeUser, handleAppStartLogin} from "../../services/HitpLogin";
import publicApi from "../../services/PublicAPI";
import config from "../../config";

export default function ConfirmPassword(props: AppPropsType) {

  const [fields, handleFieldChange] = useFormFields({
    newPassword: "",
    confirmNewPassword: ""
  });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("")

  async function handleNewPasswordSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();
    setIsLoading(true);
    if (fields.newPassword === fields.confirmNewPassword) {
      try {
        await Auth.completeNewPassword(
          getChallengeUser(),
          fields.newPassword,
          {}
        );
        await handleAppStartLogin();
        await publicApi.postPatientStatus(config.patientStatuses.accountVerified)
        props.history.push('/');
      } catch (err) {
          setError((err as Error).message);
          setIsLoading(false);
      }
    } else {
      setError("Passwords do not match.");
      setIsLoading(false);
    }
  }

  function validateNewPasswordForm(): boolean {
    return (
      fields.newPassword.length > 0 &&
      fields.confirmNewPassword.length > 0);
  }

  function handleFieldUpdate(event: FormEvent<HTMLInputElement>) {
    setError('');
    handleFieldChange(event);
  }

  return (
    <div className={"row fill"} id={"confirm-password"}>
      <div className={"col-sm-10 col-md-8 col-lg-6 col-xl-4 offset-sm-1 offset-md-2 offset-lg-3 offset-xl-4 centered-section"}>
        <form onSubmit={handleNewPasswordSubmit}>
          <h2>Change password</h2>
          <div>New Password</div>
          <input
            autoFocus
            id="newPassword"
            className="text-field"
            type="password"
            value={fields.newPassword}
            onChange={handleFieldUpdate}
          />
          <div>Confirm Password</div>
          <input
            id="confirmNewPassword"
            className="text-field"
            type="password"
            onChange={handleFieldUpdate}
            value={fields.confirmNewPassword}
          />
          {error !== '' &&
            <span id="error-text" className="form-error">{error}</span>
          }
          <SubmitButton
            id="confirm-password-btn"
            type="submit"
            isLoading={isLoading}
            disabled={!validateNewPasswordForm()}
            className={"primary-button"}
          >
            Confirm Password
          </SubmitButton>
        </form>
      </div>
    </div>
  );
}
