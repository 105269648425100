import React, {useEffect, useState} from "react";
import config from "../../config";
import {AppPropsType} from "../../App";
import publicApi from "../../services/PublicAPI";

function DebugTable(props: { data: any }) {
  return (
    <table>
      <thead>
      <tr>
        <td>Key</td>
        <td>Value</td>
      </tr>
      </thead>
      <tbody>
      {Object.keys(props.data).map(id =>
        <tr key={id}>
          <td className="remove-padding">{id}</td>
          <td className="remove-padding">{props.data[id]}</td>
        </tr>)}
      </tbody>
    </table>
  )
}

export default function Debug(appProps: AppPropsType) {
  const [publicAPIData, setPublicAPIData] = useState<any>({loading: "loading"});

  useEffect(() => {
    onLoad();
  }, []);

  function onLoad() {
    publicApi.getStatus()
      .then((res) => {
        setPublicAPIData({...res.response,...res.apiStatus})
      })
      .catch(err => ({[err.name]: err.message}))
    ;
  }

  const localDebug = {...config.version.version, ...config.version.labels, ...config.version.git, ...config.cognito};

  return (
    <div className="debug">
      <h2>Patient Front End</h2>
      <DebugTable data={localDebug}/>
      <h2>Public API status</h2>
      <DebugTable data={publicAPIData}/>
    </div>
  );
}
