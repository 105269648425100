import {BaseAPI} from "./BaseAPI";
import axios from "axios";
import config from "../config";
import {VendorOauthInitResponse, VendorStatusResponse} from "./ApiTypes";
import publicApi from "./PublicAPI";
import {VendorKeys} from "../constants";


export class VendorAPI extends BaseAPI {
  private readonly vendorConfig: {
    baseUrl: string
    revokeTokens: string
    callbackUri: string
    vendorStatus: string
    oauthInitUrl: string
    apiStatus: string
  };
  private readonly vendorKey: VendorKeys

  constructor(vendor: VendorKeys) {
    switch (vendor) {
      case VendorKeys.Abbott:
        super(config.apis.abbott.baseUrl)
        this.vendorConfig = config.apis.abbott
        break
      case VendorKeys.Dexcom:
        super(config.apis.dexcom.baseUrl)
        this.vendorConfig = config.apis.dexcom
        break
      default:
        throw new Error("Vendor not found")
    }
    this.vendorKey = vendor;
  }

  async processCallback(code: string, state: string) {
    const url = new URL(this.vendorConfig.callbackUri, this.baseUrl);
    url.searchParams.append('code', code);
    url.searchParams.append('state', state);

    await axios.get(url.href, this.getRequestConfig())
    await publicApi.postPatientStatus(config.patientStatuses.vendorAuthComplete, this.vendorKey)
  }

  getVendorStatus(): Promise<VendorStatusResponse> {
    const url = new URL(this.vendorConfig.vendorStatus, this.baseUrl);

    return axios.get(url.href, this.getRequestConfig())
      .then((res) => res.data);
  }

  getVendorOauthInitUrl(): Promise<VendorOauthInitResponse> {
    const url = new URL(this.vendorConfig.oauthInitUrl, this.baseUrl);

    return axios.get(url.href, this.getRequestConfig())
      .then((res) => {
        const url = res.data.auth_url

        return {
          auth_url: url
        }
      });
  }

  async revokeTokens() {
    if (this.vendorConfig.revokeTokens) {
      const url = new URL(this.vendorConfig.revokeTokens, this.baseUrl);
      await axios.post(url.href, {}, this.getRequestConfig());
    }

    await publicApi.postPatientStatus(config.patientStatuses.consentWithdrawn, this.vendorKey)
  }
}

const abbott = new VendorAPI(VendorKeys.Abbott)
const dexcom = new VendorAPI(VendorKeys.Dexcom)

function getVendorApi(vendor: VendorKeys): VendorAPI{
  switch (vendor) {
      case VendorKeys.Abbott:
        return abbott
      case VendorKeys.Dexcom:
        return dexcom
      default:
        throw new Error("Vendor not found")
    }
}

export {abbott, dexcom, getVendorApi};
