import {CognitoUserSession} from "amazon-cognito-identity-js";
import axios from "axios";
import {ApiStatusResponse} from "./ApiTypes";

export class BaseAPI {
  readonly baseUrl: string;
  private authToken: string = '';

  protected constructor(baseUrl: string) {
    this.baseUrl = baseUrl;
  }

  public authenticate(sessionData?: CognitoUserSession) {
    if (!sessionData) {
      this.authToken = '';
    } else {
      this.authToken = sessionData.getIdToken().getJwtToken()
    }
  }

  public getStatus(): Promise<ApiStatusResponse> {
    return axios.get('status', this.getRequestConfig())
      .then((res) => {
        return {
          apiStatus: res.data,
          response: {
            code: res.status,
            text: res.statusText
          }
        }
      })
  }

  protected getRequestConfig() {
    return {
      baseUrl: this.baseUrl,
      headers: {
        authorization: `Bearer ${this.authToken}`
      }
    }
  }

}
