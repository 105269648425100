
export class VendorKeys {
  static readonly Dexcom = 'DEXCOM';
  static readonly Lifescan = 'LIFESCAN';
  static readonly Abbott = 'ABBOTT';
  static readonly Medtrum = 'MEDTRUM';
}

export class Vendors {
  static readonly Abbott = {
    name: "Abbott",
    key: VendorKeys.Abbott,
  }
  static readonly Dexcom = {
    name: "Dexcom",
    key: VendorKeys.Dexcom,
  }
}
