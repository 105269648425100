import React, {useState} from 'react';
import logo from "./../../images/mdu-ggc-logo.svg";
import {MobileSidebar} from "./Sidebar";
import "./Header.scss";

export function DesktopHeader() {
  return (
    <nav className={"navbar header"}>
      <a href={"/"} className={"header-title"}>
        My NHS Data
      </a>
      <img className={"header-logo"} src={logo} id={"mdu-ggc-logo"} alt={"mdu-ggc-logo"}/>
    </nav>
  )
}

export function MobileHeader() {
  const [sidebarVisible, setSidebarVisible] = useState(false);

  function closeSidebar(): void {
    setSidebarVisible(false);
  }

  return (
    <nav className={"navbar header"}>
      <div className={"header-btn"} onClick={() => setSidebarVisible(!sidebarVisible)} id={"hamburger"}>
        <div className={"burgerBar"}/>
        <div className={"burgerBar"}/>
        <div className={"burgerBar"}/>
      </div>
      <a href={"/"} className={"header-title"}>
        My NHS Data
      </a>
      <MobileSidebar visible={sidebarVisible} closeSidebar={closeSidebar}/>
    </nav>
  )
}
