import React, { useEffect, useState } from "react";
import { AppPropsType } from "../../App";
import {getVendorApi} from "../../services/VendorAPI";
import {VendorKeys, Vendors} from "../../constants";

function ConnectionStatus(props: { data: any, vendor: string}) {
  if (props.data.loading) {
    return (
      <div>
        <h2>Loading...</h2>
        <div>Contacting {props.vendor} servers, please wait.</div>
      </div>
    )
  }

  if (!props.data.connected) {
    console.error(props.data)
    return (
      <div>
        <h2>Not connected</h2>
        <div>Start sharing data with {props.vendor} from the home page.</div>
      </div>
    )
  }

  return (
    <div>
      <h2>Connected successfully</h2>
    </div>
  )
}

function VendorContainer ( appProps: AppPropsType, vendorName: string, vendorKey: VendorKeys) {
  const [statusData, setStatusData] = useState({ loading: "loading" });

  useEffect(() => {
    onLoad();
  }, []);

  function onLoad() {
    getVendorApi(vendorKey).getVendorStatus()
      .catch(err => err)
      .then((data) => setStatusData(data));
  }

  function handleReturnToHomePress() {
    appProps.history.push("/");
  }

  return (
    <div className={"container row fill"}>
      <div className={"col-sm-10 col-md-8 col-lg-6 col-xl-4 offset-sm-1 offset-md-2 offset-lg-3 offset-xl-4 centered-section"}>
        <h1>{vendorName} Status</h1>
        <ConnectionStatus
          data={statusData}
          vendor={vendorName}
        />
        <button
          id="return-to-home-btn"
          type={"button"}
          className={"secondary-button"}
          onClick={handleReturnToHomePress}
        >
          Return to home
        </button>
      </div>
    </div>
  )
}

function AbbottContainer(appProps: AppPropsType){
  return VendorContainer(appProps, Vendors.Abbott.name, VendorKeys.Abbott)
}
function DexcomContainer(appProps: AppPropsType){
  return VendorContainer(appProps, Vendors.Dexcom.name, VendorKeys.Dexcom)
}
export {AbbottContainer, DexcomContainer}
