import React from "react";
import {Redirect, Route} from "react-router-dom";
import {AuthenticationRouterType} from "./RouteTypeInterface";
import {AuthenticationState, getAuthState} from "../../services/HitpLogin";

function querystring(name: string, url = window.location.href) {
  name = name.replace(/[[]]/g, "\\$&");

  const regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)", "i");
  const results = regex.exec(url);

  if (!results) {
    return null;
  }
  if (!results[2]) {
    return "";
  }

  return decodeURIComponent(results[2].replace(/\+/g, " "));
}


export default function UnauthenticatedRoute({component: C, appProps, ...rest}: AuthenticationRouterType) {
  const authState = getAuthState();
  const redirect = querystring("redirect");
  return (
    <Route
      {...rest}
      render={props =>
        authState !== AuthenticationState.Authenticated
          ? <C {...props} {...appProps} />
          : <Redirect
            to={redirect === "" || redirect === null ? "/" : redirect}
          />}
    />
  );
}
