import React, {ButtonHTMLAttributes} from "react";
import "./SubmitButton.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner} from "@fortawesome/free-solid-svg-icons/faSpinner";

interface SubmitButtonType extends ButtonHTMLAttributes<HTMLButtonElement> {
  isLoading: boolean
  centered?: boolean
}

export default function SubmitButton({
  isLoading,
  className = "",
  disabled = false,
  ...props
}: SubmitButtonType) {
  if(props.centered){
    className+= " submit-button-centered"
  }

  return (
    <button
      className={className}
      disabled={disabled || isLoading}
      {...props}
    >
      {isLoading && <FontAwesomeIcon className="loading-icon" icon={faSpinner} spin/>}
      {props.children}
    </button>
  );
}
