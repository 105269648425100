import React from "react";
import {Redirect, Route} from "react-router-dom";
import {AuthenticationRouterType} from "./RouteTypeInterface";
import {AuthenticationState, getAuthState} from "../../services/HitpLogin";

export default function AuthenticatedRoute({component: C, appProps, ...rest}: AuthenticationRouterType) {
  const authState = getAuthState();
  return (
    <Route
      {...rest}
      render={(props) =>
        authState === AuthenticationState.Authenticated
          ? <C {...props} {...appProps} />
          : <Redirect
            to={`/login?redirect=${props.location.pathname}${props.location.search}`}
          />}
    />
  );
}
