import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { AppPropsType } from "../../App";
import { AxiosError, AxiosResponse } from "axios";
import {VendorKeys, Vendors} from "../../constants";
import {getVendorApi} from "../../services/VendorAPI";

function VendorCallbackContainer(appProps: AppPropsType, vendorName: string, vendorKey: VendorKeys) {
  /*
  Retrieves authorization code and state parameters from the vendor's redirect.
  Forwards them on to our vendor api for validation
  Redirects to the internal vendor page in this site.
   */
  const history = useHistory();
  const params = new URLSearchParams(useLocation().search);
  const state = params.get("state") || '';
  const code = params.get("code") || '';

  const [response, setResponse] = useState<AxiosResponse>();

  useEffect(() => {
    onLoad();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function onLoad() {
    getVendorApi(vendorKey).processCallback(code, state)
      .then(() => {
        history.push(`/Vendors/${vendorName}`);
      })
      .catch((err: AxiosError) => {
        console.error(err);
        if (err.response) {
          setResponse(err.response);
        }
      })
  }


  function responseContent() {
    return response &&
      <div>
        <h1>{vendorName} OAuth Callback</h1>
        <h3>Error - Pairing Failed</h3>
        <dl>
          {Object.keys(response!.data).map(key => {
            return <>
              <dt>{key}</dt>
              <dd>{response!.data[key]}</dd>
            </>;
          })}
        </dl>
      </div>;
  }

  return (
    <div>
      {responseContent()}
    </div>
  )
}

function AbbottCallbackContainer(appProps: AppPropsType){
  return VendorCallbackContainer(appProps, Vendors.Abbott.name, VendorKeys.Abbott)
}
function DexcomCallbackContainer(appProps: AppPropsType){
  return VendorCallbackContainer(appProps, Vendors.Dexcom.name, VendorKeys.Dexcom)
}
export {AbbottCallbackContainer, DexcomCallbackContainer}
