import SubmitButton from "../../components/SubmitButton/SubmitButton";
import React, {FormEvent, useState} from "react";
import {Auth} from "aws-amplify";
import {useFormFields} from "../../libs/HooksLib";
import {AppPropsType} from "../../App";
import PasswordReset from "./PasswordReset";

export default function ForgotPassword(props: AppPropsType) {

  const [fields, handleFieldChange] = useFormFields({
    email: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [resetCodeSent, setResetCodeSent] = useState(false);

  async function handleFormSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();
    setIsLoading(true);
    try {
      await Auth.forgotPassword(fields.email);
      setIsLoading(false);
      setResetCodeSent(true);
    } catch (err) {
      setError((err as Error).message);
      setIsLoading(false);
    }
  }

  function handleFieldUpdate(event: FormEvent<HTMLInputElement>) {
    setError('');
    handleFieldChange(event);
  }

  function validateForm(): boolean {
    return fields.email.length > 0;
  }

  function handleReturnToSignInPress() {
    props.history.push("/login");
  }

  return (
    resetCodeSent ? (
      <PasswordReset {...props} email={fields.email}/>
    ) : (
      <div className={"row fill"} id={"forgot-password"}>
        <div className={"col-sm-10 col-md-8 col-lg-6 col-xl-4 offset-sm-1 offset-md-2 offset-lg-3 offset-xl-4 centered-section"}>
          <form onSubmit={handleFormSubmit} autoComplete={"off"}>
            <h2>Forgot password</h2>
            <div className={"subheading"}>Send a link to your email to reset your password.</div>
            <div>Email</div>
            <input
              autoFocus
              id="email"
              className="text-field"
              type="text"
              value={fields.email}
              onChange={handleFieldUpdate}
            />
            {error !== '' &&
              <span id="error-text" className="form-error">{error}</span>
            }
            <SubmitButton
              id="send-reset-link-btn"
              type="submit"
              isLoading={isLoading}
              disabled={!validateForm()}
              className={"primary-button"}
            >
              Send reset link
            </SubmitButton>
            <button
              id="return-to-sign-in-btn"
              type={"button"}
              className={"secondary-button"}
              onClick={handleReturnToSignInPress}
            >
              Return to sign in
            </button>
          </form>
        </div>
      </div>
    )
  );
}
