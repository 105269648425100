import { Auth } from "aws-amplify";
import publicApi from "./PublicAPI";
import vendorAuthApi from "./VendorAuthAPI";
import { CognitoIdToken, CognitoUser, CognitoUserSession } from "amazon-cognito-identity-js";
import { BaseAPI } from "./BaseAPI";
import {dexcom, abbott} from "./VendorAPI";

const authenticatedServices: BaseAPI[] = [
  publicApi,
  vendorAuthApi,
  abbott,
  dexcom,
]

export enum AuthenticationState {
  Unknown,
  None,
  Authenticating,
  NewPasswordRequired,
  PasswordResetRequired,
  Authenticated
}

export interface LoginUser {
  username: string,
  name?: string,
  idToken: any
}

let _authState: AuthenticationState = AuthenticationState.Unknown;
let _currentUser: LoginUser | undefined = undefined;
let _challengeUser: CognitoUser | undefined = undefined;
export let authStateHookable: AuthenticationState = AuthenticationState.Unknown;

export async function handleAppStartLogin() {
  try {
    const authData = await Auth.currentSession()
    console.log("Auth currentSession init...");
    doLogin(authData);
  } catch (e) {
    doLogout();
    if (e !== 'No current user') {
      console.error(e);
      throw new Error((e as Error).message);
    }
  }
}

export async function handleInteractiveLogin(username: string, password: string) {
  try {
    const user = await Auth.signIn(username, password);
    console.log("Auth login...");

    if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
      _challengeUser = user;
      _authState = AuthenticationState.NewPasswordRequired;
      authStateHookable = _authState;
      return;
    }
    _challengeUser = undefined;
    doLogin(user.getSignInUserSession());
  } catch (err) {
    if ((err as {code: string}).code === 'PasswordResetRequiredException') {
      _authState = AuthenticationState.PasswordResetRequired;
      authStateHookable = _authState;
      return;
    }
    _authState = AuthenticationState.None;
    authStateHookable = _authState;
    throw err;
  }
}

export async function handleLogout() {
  await Auth.signOut()
  doLogout()
}

function doLogin(authData: CognitoUserSession) {
  authenticatedServices.forEach((service) => { service.authenticate(authData) });

  const idToken: CognitoIdToken = authData.getIdToken()
  _currentUser = { username: idToken.payload.sub, idToken: idToken, name: idToken.payload.name }
  _authState = AuthenticationState.Authenticated;
  authStateHookable = _authState;
}

function doLogout() {
  authenticatedServices.forEach((service) => { service.authenticate(undefined) });

  _currentUser = undefined;
  _authState = AuthenticationState.None;
  authStateHookable = _authState;
}

export function getAuthState() {
  return _authState;
}

export function getCurrentUser() {
  return _currentUser;
}

export function getChallengeUser() {
  return _challengeUser;
}
