import {ChangeEvent, useState} from "react";
import {useMediaQuery} from "react-responsive";

export function useFormFields(initialState: any) {
    const [fields, setValues] = useState(initialState);

    return [
        fields,
        function (event: ChangeEvent<HTMLInputElement>) {
            setValues({
                ...fields,
                [event.currentTarget.id]: event.currentTarget.value
            });
        }
    ];
}

export function useMobile(){
  return useMediaQuery({query: '(max-width: 576px)'});
}
