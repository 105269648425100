import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner} from "@fortawesome/free-solid-svg-icons/faSpinner";
import {VendorKeys} from "../../constants";
import abbottLogo from "../../images/vendor-logos/abbott-logo.png";
import freestyleLibre from "../../images/vendor-logos/freestyle-libre.png";
import freestyleLibre2 from "../../images/vendor-logos/freestyle-libre2.png";
import dexcomLogo from "../../images/vendor-logos/dexcom-logo.png";
import dexcomG6 from "../../images/vendor-logos/dexcom-g6.png";
import dexcomG7 from "../../images/vendor-logos/dexcom-g7.png";

interface VendorObj {
    [key: string]: {
        logo: string,
        devices: {
            src: string,
            key: string,
        }[]
    }
}

let vendorImages: VendorObj = {}
vendorImages[VendorKeys.Abbott] = {
    logo: abbottLogo,
    devices: [
        {
            src: freestyleLibre,
            key: "freestyleLibre",
        },
        {
            src: freestyleLibre2,
            key: "freestyleLibre2",
        },
    ]
}
vendorImages[VendorKeys.Dexcom] = {
    logo: dexcomLogo,
    devices: [
        {
            src: dexcomG6,
            key: "dexcomG6",
        },
        {
            src: dexcomG7,
            key: "dexcomG7",
        },
    ]
}

export default function VendorTable(
    vendors: any,
    getVendorStatus: any,
    openGiveConsentModal: any,
    openRevokeConsentModal: any,
    mobile: boolean,
) {
    if (mobile) {
        return mobileVendorTable(
            vendors,
            getVendorStatus,
            openGiveConsentModal,
            openRevokeConsentModal,
        )
    } else {
        return desktopVendorTable(
            vendors,
            getVendorStatus,
            openGiveConsentModal,
            openRevokeConsentModal,
        )
    }
}

function desktopVendorTable(
    vendors: [string],
    getVendorStatus: any,
    openGiveConsentModal: any,
    openRevokeConsentModal: any,
) {
    return (
        <table className={"table table-bordered vendor-table"} id={"vendor-table"}>
            <thead>
            <tr>
                <th className={"col-sm-3"}>Vendor</th>
                <th className={"col-sm-4"}>Devices</th>
                <th className={"col-sm-3"}>Status</th>
                <th className={"col-sm-2"}></th>
            </tr>
            </thead>
            <tbody>
            {vendors.map(vendor => {
                if (vendorImages.hasOwnProperty(vendor)) {
                    return (
                        <tr key={vendor.toLowerCase()} id={vendor.toLowerCase() + "-row"}>
                            <td>
                                <img
                                    src={vendorImages[vendor].logo}
                                    alt={vendor.toLowerCase() + "-logo"}
                                    className={"vendor-logo"}
                                />
                            </td>
                            <td>
                                {vendorImages[vendor].devices.map((device) => {
                                    return (
                                        <img
                                            src={device.src}
                                            key={device.key}
                                            alt={device.key}
                                            className={"d-table-row device-logo"}
                                        />
                                    )
                                })}
                            </td>
                            <td>
                                {getVendorStatus(vendor) ?
                                    getVendorStatus(vendor) === "Connected" ?
                                        "Sharing" :
                                        "Not sharing" :
                                    <FontAwesomeIcon className="loading-icon" icon={faSpinner} size={"2x"} spin/>
                                }
                            </td>
                            <td>
                                {getVendorStatus(vendor) ?
                                    <button
                                        className={"btn btn-link"}
                                        onClick={() => getVendorStatus(vendor) === "Connected" ? openRevokeConsentModal(vendor) : openGiveConsentModal(vendor)}
                                        id={(getVendorStatus(vendor) === "Connected" ? "stop" : "start") + `-sharing-${vendor.toLowerCase()}`}
                                    >
                                        {getVendorStatus(vendor) === "Connected" ? "Stop Sharing" : "Start Sharing"}
                                    </button> :
                                    <FontAwesomeIcon className="loading-icon" icon={faSpinner} size={"2x"} spin/>
                                }
                            </td>
                        </tr>
                    )
                }
            })}
            </tbody>
        </table>
    )
}

function mobileVendorTable(
    vendors: [string],
    getVendorStatus: any,
    openGiveConsentModal: any,
    openRevokeConsentModal: any,
) {
    return (
        <table className={"table table-bordered vendor-table"} id={"mobile-vendor-table"}>
            <tbody>
            {vendors.map((vendor) => {
                return (
                    <tr key={vendor.toLowerCase()} id={vendor.toLowerCase() + "-row"}>
                        <div className={"row"}>
                            <div className={"col-6"}>
                                <div>
                                    <img
                                        src={vendorImages[vendor].logo}
                                        alt={vendor.toLowerCase() + "-logo"}
                                        className={"vendor-logo"}
                                    />
                                </div>
                                {vendorImages[vendor].devices.map((device) => {
                                    return (
                                        <img
                                            src={device.src}
                                            key={device.key}
                                            alt={device.key}
                                            className={"d-table-row device-logo"}
                                        />
                                    )
                                })}
                            </div>
                            <div className={"col-6 d-flex flex-column justify-content-around"}>
                                <div className={"d-flex justify-content-end"}>
                                    {getVendorStatus(vendor) ?
                                        <button
                                            className={"btn btn-link"}
                                            onClick={() => getVendorStatus(vendor) === "Connected" ? openRevokeConsentModal(vendor) : openGiveConsentModal(vendor)}
                                            id={(getVendorStatus(vendor) === "Connected" ? "stop" : "start") + `-sharing-${vendor.toLowerCase()}`}
                                        >
                                            {getVendorStatus(vendor) === "Connected" ? "Stop Sharing" : "Start Sharing"}
                                        </button> :
                                        <FontAwesomeIcon className="loading-icon" icon={faSpinner} size={"2x"} spin/>
                                    }
                                </div>
                                <div className={"d-flex justify-content-end"}>
                                    {getVendorStatus(vendor) ?
                                        getVendorStatus(vendor) === "Connected" ?
                                            "Sharing" :
                                            "Not sharing" :
                                        ""
                                    }
                                </div>
                            </div>
                        </div>
                    </tr>
                )
            })}
            </tbody>
        </table>
    )
}
