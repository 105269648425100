import React, {useEffect, useState} from 'react';
import {useLocation, withRouter} from "react-router-dom";
import Routes from "./Routes";
import PageFooter from "./components/PageLayout/Footer";
import {DesktopHeader, MobileHeader} from "./components/PageLayout/Header";
import {DesktopSidebar} from "./components/PageLayout/Sidebar";
import {useHistory} from "react-router";
import {History} from "history";
import {AuthenticationState, getAuthState, handleAppStartLogin,} from "./services/HitpLogin";
import './App.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import {useMobile} from "./libs/HooksLib";

export interface AppPropsType {
  history: History,
}

export interface AppPropsObject {
  appProps: AppPropsType
}

function App() {
  const [authState, setAuthState] = useState(getAuthState());

  const appProps: AppPropsType = {
    history: useHistory()
  }

  useEffect(() => {
    handleAppStartLogin()
      .then(() => {
        setAuthState(getAuthState())
      })
      .catch(ignore => {
        console.error(ignore);
      });
  });

  const isRouteHome = useLocation().pathname === "/";

  if (useMobile()) {
    return ( // mobile layout
      <div className={"flex-grow-1"}>
        <MobileHeader/>
        <div className={"container page-content"}>
          {authState !== AuthenticationState.Authenticating && authState !== AuthenticationState.Unknown &&
            <Routes appProps={appProps}/>}
        </div>
      </div>
    )
  } else {
    return ( // desktop layout
      <div className={"hitp-container"}>
        <DesktopHeader/>
        <div className={"page-body"}>
          {isRouteHome && <DesktopSidebar/>}
          <div className={"page-content d-flex"}>
            <div className={"container d-flex flex-column justify-content-between"}>
              {authState !== AuthenticationState.Authenticating && authState !== AuthenticationState.Unknown &&
                <Routes appProps={appProps}/>}
              <PageFooter/>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(App);
//   <!-- Browsers with ES module support load these files -->
//   <script type="module" src="/assets/scripts/dist/pattern-library.js?1611580890"></script>
//   <!-- Older browsers load these files -->
//   <!-- (and module-supporting browsers know *not* to load these files) -->
//   <script noModule="" src="/assets/scripts/dist/pattern-library.es5.js?1611580890"></script>
