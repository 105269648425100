import {BaseAPI} from "./BaseAPI";
import axios from "axios";
import config from "../config";
import {VendorOauthInitResponse, VendorStatusResponse} from "./ApiTypes";



export class VendorAuthAPI extends BaseAPI {

  constructor() {
    super(config.apis.vendorAuth.baseUrl);
  }

  processCallback(vendorKey: string, code: string, state: string): Promise<any> {
    const url = new URL(`vendor/callback/${vendorKey}`, this.baseUrl);
    url.searchParams.append('code', code);
    url.searchParams.append('state', state);


    return axios.get(url.href, this.getRequestConfig())
      .then((res) => {
        return undefined
      })
  }

  getVendorStatus(vendorKey: string): Promise<VendorStatusResponse> {
    const url = new URL('vendor/status', this.baseUrl);
    url.searchParams.append('vendor', vendorKey);

    return axios.get(url.href, this.getRequestConfig())
      .then((res) => res.data);

  }

  getVendorOauthInitUrl(vendorKey: string): Promise<VendorOauthInitResponse> {
    const url = new URL('vendor/auth-url', this.baseUrl);
    url.searchParams.append('vendor', vendorKey);

    return axios.get(url.href, this.getRequestConfig())
      .then((res) => {
        const url = res.data.auth_url || res.data[`${vendorKey}_auth_url`] || ''

        return {
          auth_url: url
        }
      });

  }
}

const vendorAuthApi = new VendorAuthAPI();
export default vendorAuthApi;
